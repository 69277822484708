@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
@import "variables";
@import "~bootstrap/scss/bootstrap";

//HTML
html,
body {
  scroll-behavior: smooth;
  overflow: auto;
  padding: 0;
  margin: 0;
  width: 100%;
  font-weight: 400;
  color: #222;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
}

body {
  position: relative;
  width: 100%;
  padding: 0;
  margin: 0;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  background: #f0f1f1;
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
}
//mixins
@mixin lineclamp($lines: 1) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin circle($size: 25px) {
  width: $size;
  height: $size;
  border-radius: $size;
  @extend .flex-center;
}
//links
a {
  color: $info;
  text-decoration: none;
}
//flex
.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.flex-space-b {
  @extend .flex-row;
  justify-content: space-between;
}

.flex-middle {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
}
.flex-center {
  @extend .flex-middle;
}

//breadcrumb

.breadcrumb {
  .breadcrumb-item {
    & + .breadcrumb-item:before {
      content: "\203A";
    }
    a {
      &:not(.active) {
        color: $info;
        font-weight: $semiBold;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.s_title__01 {
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: $semiBold;
}

.card {
  margin-bottom: $margin;
  box-shadow: 0 1px 1px 0 rgba(0, 28, 36, 0.3),
    1px 1px 1px 0 rgba(0, 28, 36, 0.15), -1px 1px 1px 0 rgba(0, 28, 36, 0.15);
  border-radius: 0;
  border-top: 1px solid #eaeded;
  .card-header {
    border-bottom: 1px solid #eaeded;
    padding: 12px 20px;
    font-size: 1.2rem;
    font-weight: $semiBold;
    color: $black_light_22;
  }
}
.modal {
  .modal-header {
    padding: 12px 20px;
    .modal-title {
      font-size: 1.2rem;
      font-weight: $semiBold;
      color: $black_light_22;
    }
  }
}

.nav-tabs {
  .nav-link {
    &.active {
      border-top-color: $primary;
    }
  }
}
// inputs

input[type="text"],
input[type="email"],
input[type="password"],
input[type="date"],
input[type="search"],
input[type="number"],
input[type="tel"],
textarea,
select {
  outline: none;
  background-color: $white;
  &:not[plaintext] {
    border: 1px solid $white_light_70;
  }

  height: 40px;
  color: $black_light_55;
  &:active,
  &:focus {
    outline: none !important;
    box-shadow: none !important;
    background-color: $white;
    border-width: 2px;
    border-color: $info;
  }
}

.form-control[readonly] {
  background-color: #fff !important;
  padding-left: 0;
  @extend .text-muted;
  border-color: transparent;
}
.form-group {
  margin-bottom: $margin;
  &.hidden {
    visibility: hidden;
    * {
      width: 0 !important;
      height: 0 !important;
      margin-bottom: 0 !important;
    }
  }
}

//buttons
.btn {
  font-size: 0.9rem;
  line-height: 1.3rem;
  font-weight: $bold;
  &.btn-secondary,
  &.btn-primary,
  &.btn-info,
  &.btn-danger,
  &.btn-success {
    color: $white;
  }
}
.btn-list-01 {
  @extend .flex-row;
  justify-content: flex-end;
  > *:not(:last-child) {
    display: block;
    margin-right: $margin;
  }
}

.required:after {
  top: 0;
  content: "*";
  margin-left: 5px;
  font-size: 0.5rem;
  color: $danger;
}
.optional:after {
  top: 0;
  content: "(Facultatif)";
  margin-left: 5px;
  font-size: 0.5rem;
  @extend .text-muted;
}

.c--tabs {
  border-bottom: 1px solid #eaeded;
  font-size: 1.2rem;
  font-weight: $semiBold;
  color: $black_light_22;
  background-color: $white;
  margin-bottom: $margin;
  .ct--wrapper {
    @extend .flex-row;
    a.ct--item {
      display: block;
      padding: 12px 20px;
      color: $black_light_55;
      border-bottom: 1px solid transparent;
      font-size: 0.9rem;
      text-transform: uppercase;
      &:not(.active):hover {
        color: $info;
      }
      &.active {
        color: $primary;
        border-bottom-color: $primary;
        background-color: $white_light_90;
      }
    }
  }
}

.nav-options--01 {
  @extend .flex-row;
  justify-content: flex-end;
  margin-bottom: $margin;
  > *:not(:last-child) {
    margin-right: $margin;
  }
}

//switch
.switch--wrapper {
  cursor: pointer;
  @extend .flex-row;
  .switch-caption {
    margin-left: 8px;
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;

    input {
      display: none;
    }
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 24px;
    &:before {
      position: absolute;
      content: "";
      height: 15px;
      width: 15px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 50%;
    }
    &.is-invalid {
      background-color: $danger !important;
    }
  }
  input:checked + .slider {
    background-color: $success;
  }
  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  } /* Rounded sliders */
  input:disabled + .slider {
    background-color: $muted;
  }
}

table {
  tbody {
    tr {
      cursor: pointer;
      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }
  }
}
.text-line-througth {
  text-decoration: line-through;
}

.mh-100 {
  min-height: 100px !important;
}

.mh-200 {
  min-height: 200px !important;
}
.bold {
  font-weight: $bold;
}

.colorable {
  &.success {
    color: $success;
  }
  &.info {
    color: $info;
  }
  &.danger {
    color: $danger;
  }
  &.warning {
    color: $warning;
  }
  &.muted {
    color: $muted;
  }
}
.bgcolorable {
  &.success {
    background-color: $success;
  }
  &.info {
    background-color: $info;
  }
  &.danger {
    background-color: $danger;
  }
  &.warning {
    background-color: $warning;
  }
  &.muted {
    background-color: $muted;
  }
}
.mw-200 {
  max-width: 200px !important;
}

.item-details-indicator {
  span {
    display: inline-block;
    &:not(:last-child) {
      margin-right: 10px;
      padding-right: 10px;
      border-right: 1px solid #aaa;
    }
  }
}

.bagde--01 {
  margin-block: 5px;
  span {
    display: inline-block;
    border: 1px solid $white_light_80;
    border-radius: 25px;
    padding: 3px 10px;
    margin-right: 10px;
    font-weight: 500;
  }
  a {
    @extend .remover-01;
  }
}
.remover-01 {
  display: inline-block;
  @include circle(25px);
  border: 1px solid $white_light_80;
  text-align: center;
  color: $muted;
  &:hover {
    background-color: $danger;
    color: $white;
  }
}

.dropdown {
  width: 100%;
  .dropdown-menu {
    width: 100%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding-block: 0;
    .dropdown-item {
      .caption {
        font-weight: 500;
        display: block;
        line-height: 1.8;
      }
      .subcaption {
        font-size: 10px;
        color: $muted;
        font-style: italic;
      }
    }
  }
}
.text-white {
  color: #fff !important;
}

//
.is-loading--01 {
  position: relative;
  height: 4px;
  display: block;
  width: 100%;
  &.show {
    background-color: #acece6;
    .tp--inner-bar {
      background-color: #26a69a;
    }
  }

  border-radius: 2px;
  background-clip: padding-box;
  margin: 0 0 1rem 0;
  overflow: hidden;

  .tp--inner-bar:before {
    content: "";
    position: absolute;
    background-color: inherit;
    top: 0;
    left: 0;
    bottom: 0;
    will-change: left, right;
    -webkit-animation: indeterminate 2.1s
      cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
    animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395)
      infinite;
  }
  .tp--inner-bar:after {
    content: "";
    position: absolute;
    background-color: inherit;
    top: 0;
    left: 0;
    bottom: 0;
    will-change: left, right;
    -webkit-animation: indeterminate-short 2.1s
      cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1)
      infinite;
    -webkit-animation-delay: 1.15s;
    animation-delay: 1.15s;
  }
}
@-webkit-keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}
@keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}
//
