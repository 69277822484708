@import "configs.scss";
#master--layout {
  @extend .flex-row;
  align-items: flex-start;
  flex-wrap: nowrap;
  .ml--m-menus--wrapper,
  .ml--m-content--wrapper {
    height: 100vh;
    min-height: 100vh;
    overflow: scroll;
    padding: $padding;
  }
  .ml--m-menus--wrapper {
    width: 240px;
    min-width: 240px;
    background-color: $white;
    border-right: 1px solid $border_color;
  }
  .ml--m-content--wrapper {
    flex-grow: 1;
    .m--top--bar {
      background-color: #fff;
      border-bottom: 1px solid $white_light_70;
      height: 50px;
      @extend .flex-row;
      justify-content: flex-end;
      padding: 10px;
    }
  }
}
.msm--01 {
  section {
    margin-bottom: 2rem;
    .section--title {
      margin-bottom: $margin;
      font-size: 15px;
      font-weight: $bold;
      color: $black_light_22;
    }
  }
  .menu-list {
    @extend .list-unstyled;
    padding-left: $padding;
    .menu-item {
      display: block;
      font-weight: $semiBold;
      color: $black_light_55;
      margin-bottom: 0.5rem;
      &.active {
        color: $secondary;
        font-weight: $bold;
      }
      &:hover {
        color: $secondary;
      }
    }
  }
}

.paginate--wrapper--01 {
  margin-top: $margin;
  margin-bottom: $margin;
  @extend .flex-middle;
  .pg-w {
    display: inline-block;
  }
}
.cv-m--displayer {
  .type--caption {
    font-size: 1rem;
    font-weight: $bold;
    margin-bottom: $margin;
    small {
      cursor: pointer;
      text-decoration: underline;
      color: $muted;
      font-size: 0.8rem;
    }
  }
  .options {
    ul {
      @extend .list-unstyled;
      li {
        color: $black_light_55;
        font-size: 0.9rem;
      }
    }
  }
}
.variant-ading--displayer--01 {
  @extend .flex-row;
  width: 100%;
  margin-bottom: 0.5rem;
  border: 1px solid $white_light_80;
  .caption--c {
    flex-grow: 1;
    padding: 0 0.5rem;
  }
  button:hover {
    color: $white;
    background-color: $danger;
  }
}

.lcf--img-displayer {
  width: 100%;
  height: auto;
  border: 1px solid $white_light_80;
  border-radius: $radius;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  img {
    width: 100%;
  }
  .default-tag {
    position: absolute;
    bottom: 5px;
    left: 5px;
    font-size: 0.5rem;
    display: inline-block;
    background-color: $success;
    color: $white;
    border-radius: $radius;
    padding: 3px 10px;
    font-weight: bold;
  }
  .set-default {
    position: absolute;
    bottom: 5px;
    left: 5px;
    font-size: 0.5rem;
    display: inline-block;
    background-color: $secondary;
    color: $white;
    border-radius: $radius;
    padding: 3px 10px;
    font-weight: bold;
    visibility: hidden;
  }
  .remove-img {
    position: absolute;
    height: 20px;
    width: 20px;
    top: 5px;
    right: 5px;
    font-size: 0.5rem;
    background-color: $danger;
    color: $white;
    border-radius: 100%;
    padding: 3px 10px;
    font-weight: bold;
    text-align: center;
    @extend .flex-center;
    visibility: hidden;
  }
  &:hover {
    .remove-img,
    .set-default {
      visibility: visible;
    }
  }
}
.order-status--01 {
  font-size: 1rem;
  font-weight: bold;
  @extend .colorable;
  @extend .flex-row;
  .o--dot {
    @include circle(10px);
    @extend .bgcolorable;
    margin-right: 5px;
  }
}

.auth-container {
  min-height: 100vh;
  @extend .flex-center;
  .auth--wrapper {
    width: 90%;
    max-width: 370px;
    .logo-container {
      @extend .flex-center;
      margin-bottom: 25px;
      img.logo {
        height: 50px;
      }
    }
  }
}

.order-show--page {
  .hdr {
    @extend .flex-row;
    margin-bottom: $margin;
    .s--title {
      font-size: 15px;
      font-weight: bold;
      color: $black_light_33;
      margin-right: 10px;
      span {
        @extend .text-muted;
      }
    }
  }
  .item-displayer {
    margin-bottom: $margin;
    position: relative;
    @extend .flex-row;
    align-items: flex-start;
    .image-container {
      margin-right: 10px;
      img {
        width: 70px;
        min-width: 70px;
      }
    }
    .details-container {
      flex-grow: 1;
      @extend .flex-space-b;
      align-items: flex-start;
      .infos {
        max-width: 50%;
        .i--name {
          font-size: 12px;
          @include lineclamp(2);
          text-overflow: ellipsis;
          font-weight: normal;
        }
      }
      .unit-price,
      .quantity,
      .total {
        text-align: center;
        font-size: 17px;
        color: $primary;
        font-weight: $semiBold;
        &:not(:last-child) {
          border-right: 1px solid $white_light_80;
          padding-right: 10px;
          margin-right: 10px;
        }
      }
    }
    .supplier-link {
      position: absolute;
      bottom: 10px;
      right: 10px;
    }
  }
}

.ad-indicator-overview {
  min-height: 70px;
  padding: 1rem 2rem;
  margin-bottom: 1rem;
  * {
    color: white !important;
  }
  .id-title {
    font-size: 0.9rem;
  }
  .id-value {
    font-size: 2rem;
    font-weight: bold;
  }
}
