$secondary: #545b64;
$primary: #000;
$muted: #777777;

$white: #ffffff;
$white_light_95: #f7f7f7;
$white_light_90: #f5f6f8;
$white_light_85: #f2f2f2;
$white_light_80: #ebebeb;
$white_light_70: #cccccc;
$border_color: #e9ebeb;

$black: #000000;
$black_light_0F: #0f1111;
$black_light_22: #222222;
$black_light_22_opacited: #22222226;
$black_light_33: #333333;
$black_light_55: #545b64;
$black_light_77: #777777;
$black_light_99: #999999;

$success: rgb(92, 184, 92);
$success_opacited: #5cb85c26;
$info: #42a2f5;
$info_opacited: #42a2f526;
$danger: #df4759;
$danger_opacited: #df475926;
$warning: #f0ad4e;
$warning_opacited: #f0ad4e26;
$muted: #777;

$radius: 0.5rem;
$marginBottom: 1rem;
$margin: 1rem;
$padding: 1rem;

$semiBold: 600;
$bold: 700;
$superBold: 900;
